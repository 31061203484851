/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* catalog menu */
.cat--menu {
    width: 365px;
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    top: 0px;
    left: 0;
    .cat--menu__title {
        display: block;
        font-family: $bold;
        font-size: 18px;
        text-transform: uppercase;
        color: #fff;
        background-color: #ef7f1a;
        padding: 10px 40px;

    }
    ul {
        @include ul-default;
        li {
            a {
                display: block;
                position: relative;
                font-size: 16px;
                color: #fff;
                text-decoration: none;
                padding: 10px 40px;
                &:after {
                    content: '';
                    height: 1px;
                    background-color: #c17129; 
                    position: absolute;
                    bottom: 0;
                    left: 22px;
                    right: 22px;
                    z-index: -2;
                }
                &:hover {
                    background-color: #f7a052;
                    color: #fff;
                }
            }
            &.active a {
                background-color: #f7a052;
                color: #fff;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        width: 300px;
        .cat--menu__title {
            font-size: 18px;
            padding: 15px 20px;
        }
        ul li a {
            padding: 12px 20px;
            font-size: 16px;
            line-height: 1.2;
        }
    }
    @media screen and (min-width: 992px) {
        &.fixed {
            position: fixed;
            top: 40px;
        }
    }
    @media screen and (max-width: 991px) {
        display: none;
        width: auto;
        top: 97px;
        .cat--menu__title {
            font-size: 16px;
            padding: 10px 10px;
        }
        ul li a {
            padding: 5px 10px;
            &:after {
                left: 10px;
                right: 10px;
            }
        }
    }
}
/* catalog menu */

/* main slider */
.slider {
	margin-bottom: 0px;
	.slick-slide {
		overflow: hidden;
		position: relative;
		outline: none;
		img {
			display: block;
			width: 100%;
			height: auto;
		}
		.slider__block {
            width: 790px;
            background-color: rgba(239, 127, 26, 0.7);
            font-family: $medium;
            font-size: 18px;
            color: #fff;
			position: absolute;
			left: 50%;
            bottom: 0;
            padding: 40px 30px;
            margin-left: -587px;
            box-sizing: border-box;
            .slider__block__title {
                display: block;
                font-family: $bold;
                font-size: 38px;
                color: #fff;
            }
		}
	}
    .slick-arrow {
        width: 30px;
        height: 30px;
        transition: 300ms;
        z-index: 1;
        &:before {
            display: none;
        }
        &:after {
            content: '\f053';
            font-family: 'fontAwesome';
            font-size: 30px;
            text-align: center;
            color: #ef7f1a;
            line-height: 30px;
        }
        &:hover {
            opacity: 0.7;
        }
        &.slick-prev {
            left: 10%;
            visibility: hidden;
        }
        &.slick-next {
            right: 10%;
            &:after {
                transform: rotate(180deg);
            }
        }
    }
	.slick-dots {
		bottom: 30px;
		li {
			margin: 0px 2px;
			button {
				width: 15px;
				height: 15px;
				background-color: #efefef;
				border-radius: 100%;
				border: 1px solid #3361eb;
				&:before {
					display: none;
				}
				&:hover {
					background-color: #3361eb;
				}
			}
			&.slick-active {
				button {
					background-color: #3361eb;
				}
			}
		}
	}
    @media screen and (max-width: 1880px) {
        .slick-slide {
            .slider__block {
                left: 365px;
                margin: 0;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .slick-slide {
            .slider__block {
                left: 300px;
                padding: 30px 20px;
            }
        }
        .slick-arrow.slick-next {
            right: 5%;
        }
    }
    @media screen and (max-width: 1199px) {
        .slick-slide {
            .slider__block {
                width: auto;
                right: 0px;
                padding: 15px 20px;
                .slider__block__title {
                    font-size: 32px;
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        .slick-slide {
            .slider__block {
                left: 0;
                padding: 15px 40px;
                .slider__block__title {
                    font-size: 28px;
                }
                p {
                    font-size: 16px;
                    margin-bottom: 0;
                }
            }
        }
        .slick-arrow {
            width: 20px;
            height: 20px;
            &:after {
                font-size: 20px;
                line-height: 20px;
                color: #fff;
                text-shadow: 0px 0px 5px #000;
            }
            &.slick-prev {
                visibility: visible;
                left: 10px;
            }
            &.slick-next {
                right: 10px;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .slick-slide {
            height: 200px;
            > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
            .slider__block {
                padding: 10px 30px;  
                .slider__block__title {
                    font-size: 20px;
                }
                p {
                    font-size: 14px;
                }
            }
        }
        .slick-arrow {
            &.slick-prev {
                left: 5px;
            }
            &.slick-next {
                right: 5px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        .slick-slide {
            .slider__block {
                .slider__block__title {
                    font-size: 18px;
                }
                p {
                    font-size: 12px;
                }
            }
        }
    }
}
/* main slider */

/* main about */
.about {
    position: relative;
    overflow: hidden;
    h1 {
        text-align: left;
    }
    > img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        object-fit: cover;
        object-position: center center;
    }
    .container {
        position: relative;
        z-index: 1;
    }
    @media screen and (max-width: 480px) {
        p img {
            display: none;
            max-width: 100%;
            float: none!important;
            margin: 10px 0!important;
        }
    }
}
/* main about */

/* main catalog inside */
.cat--inside {
    background-color: #ededed;
    box-shadow: inset 0px 11px 15px -10px rgba(0,0,0,0.2), inset 0px -11px 15px -10px rgba(0,0,0,0.2);
    .cat--inside__col {
        display: block;
        position: relative;
        color: #393939;
        background-color: #fff;
        padding: 28px;
        border: 2px solid transparent;
        margin-bottom: 38px;
        transition: 300ms;
        .cat--inside__col__img {
            display: block;
            height: 150px;
            padding: 10px 0;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center center;
            }
        }
        a {
            display: block;
            text-decoration: none;
        }
        h4 {
            min-height: 51px;
            font-size: 16px;
            font-family: $sBold;
            color: #393939;
            text-align: center;
        }
        ul {
            min-height: 155px;
            margin: 10px 0 10px 10px;
            li {
                font-size: 14px;
                &:before {
                    top: 2px;
                }
            }
        }
        .btn {
            display: table;
            padding: 5px 60px!important;
            margin: 0 auto;
        }
        .stiker {
            position: absolute;
            top: 16px;
            right: -18px;
            line-height: 1;
            span {
                display: block;
                font-size: 14px;
                color: #fff;
                text-align: center;
                padding: 10px;
                margin-bottom: 10px;
                &.gray {
                    background-color: #2b2a29;
                }
                &.orange {
                    background-color: #ef7f1a;
                }
            }
        }
        .cat--inside__col__btn {
            .btn {
                @include inline-block;
                padding: 5px 20px!important;
                &:nth-of-type(2) {
                    float: right;
                }
                &.more {
                    background-color: #fff!important;
                    color: #ef7f1a!important;
                    &:hover {
                        background-color: #ef7f1a!important;
                        color: #fff!important;
                    }
                }
            }
        }
        &:hover {
            border: 2px solid #ef7f1a;
            a {
                color: #393939;
            }
        }
    }
    .cat--inside__line {
        height: 10px;
        width: 100%;
        background-color: #fff;
        position: relative;
        &:after {
            content: '';
            height: 10px;
            width: 165px;
            background-color: #ef7f1a;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
    .cat--inside__slider {
        padding: 0 25px;
        .slick-slide {
            outline: none;
            overflow: hidden;
            padding: 0 15px;
            .cat--inside__col {
                padding: 24px;
                margin-bottom: 0;
                .cat--inside__col__btn .btn {
                    padding: 5px 14px!important;
                }
            }
        }
        .slick-arrow {
            height: 35px;
            transition: 300ms;
            z-index: 1;
            &:before {
                display: none;
            }
            &:after {
                font-family: 'fontAwesome';
                font-size: 55px;
                color: #ef7f1a;
                line-height: 35px;
            }
            &.slick-prev {
                left: 10px;
                &:after {
                    content: '\f104';
                }
            }
            &.slick-next {
                right: 10px;
                &:after {
                    content: '\f105';
                }
            }
            &:hover {
                opacity: 0.5;
            }
        }   
    }
    @media screen and (max-width: 1550px) {
        .cat--inside__col .cat--inside__col__btn {
            .btn {
                height: 25px;
                font-size: 14px;
                padding: 4px 5px!important;
                &:nth-of-type(2) {
                    margin-left: 5px;
                }
            }
        }
        .cat--inside__slider .slick-slide .cat--inside__col .cat--inside__col__btn .btn {
            padding: 4px 5px!important;
        }
    }
    @media screen and (max-width: 1280px) {
        .cat--inside__col .cat--inside__col__btn {  
            .btn { 
                display: table;
                margin: 5px auto;
                &:nth-of-type(2) {
                    float: none;
                    margin: 5px auto;
                }
            }
        }
    }
    @media screen and (max-width: 1199px) {
        .cat--inside__col {
            padding: 15px;
            margin-bottom: 25px;
            .stiker {
                right: 0;
                top: 10px;
                span {
                    padding: 5px 10px;
                    margin-bottom: 5px;
                }
            }
            .cat--inside__col__img {
                height: 120px;
            }
            .btn {
                padding: 5px 20px!important;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .cat--inside__col {
            border: 2px solid #ef7f1a;
            padding: 10px;
            margin-bottom: 15px;
            h4 {
                min-height: 0px;
            }
            ul {
                min-height: 0px;
            }
            .cat--inside__col__btn {
                .btn { 
                    @include inline-block;
                    margin: 5px 5px;
                    &:nth-of-type(2) {
                        margin: 5px 5px;
                    }
                }
            }
        }
    }
}
/* main catalog inside */

/* main advantage */
.advantage {
    position: relative;
    overflow: hidden;
    h3 {
        font-family: $sBold;
        margin: 0 0 30px;
    }
    > img {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        object-fit: cover;
        object-position: center center;
    }
    .container {
        position: relative;
        z-index: 1;
    }
    .advantage__list {
       @include ul-default;
        li {
            position: relative;
            padding-left: 60px;
            margin-bottom: 30px;
            span {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
    @media screen and (max-width: 767px) {
        > img {
            display: none;
        }
    }
}
/* main advantage */

/* main catalog */
.cat {
    background-color: #ededed;
    position: relative;
     box-shadow: inset 0px 11px 15px -10px rgba(0,0,0,0.2), inset 0px -11px 15px -10px rgba(0,0,0,0.2);
    > img {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
    .cat__col {
        display: block;
        text-decoration: none;
        background-color: #fff;
        margin-bottom: 20px;
        transition: 0ms;
        .cat__col__img {
            height: 245px;
            border: 2px solid transparent;
            border-bottom: none;
            text-align: center;
            padding: 15px 10px;
            overflow: hidden;
            transition: 300ms;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
        .cat__col__title {
            display: table;
            height: 47px;
            width: 100%;
            background-color: #2b2a29;
            font-family: $sBold;
            font-size: 18px;
            color: #fff;
            text-align: center;
            padding: 0 10px;
            transition: 200ms;
            span {
                display: table-cell;
                vertical-align: middle;
            }
        }
        &:hover {
            .cat__col__img {
                border: 2px solid #ef7f1a;
                border-bottom: none;
            }
            .cat__col__title {
                background-color: #ef7f1a;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .cat__col {
            .cat__col__img {
                height: auto;
                border: 2px solid #ef7f1a;
                border-bottom: none;
                img {
                    height: auto;
                    object-fit: inherit;
                }
            }
            .cat__col__title {
                height: auto;
                background-color: #ef7f1a;
                padding: 5px 10px;
            }
        }
        > img {
            display: none;
        }
    }
}
/* main catalog */

/* main news */
.news {
    position: relative;
    overflow: hidden;
    .news__col {
        display: block;
        text-decoration: none!important;
        transition: 0ms;
        margin-bottom: 15px;
        .news__col__img {
            overflow: hidden;
            img {
                width: 100%;
                transition: 300ms;
            }
        }
        .news__col__block {
            color: #2b2a29;
            padding: 25px 20px;
            transition: 300ms;
            .date {
                color: #666666;
                transition: 300ms;
            }
            h5 {
                font-family: $sBold;
                color: #ef7f1a;
                transition: 300ms;
            }
        }
        &:hover {
            .news__col__img {
                img {
                    transform: scale(1.1);
                }
            }
            .news__col__block {
                background-color: #ef7f1a;
                color: #fff;
                .date {
                    color: #fff;
                }
                h5 {
                    color: #ffffff;
                    text-decoration: underline;
                }
            }
        }
    }
    > img {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    @media screen and (max-width: 767px) {
        .news__col {
            margin-bottom: 20px;
            .news__col__block {
                padding: 20px 15px;
            }
        }
        > img {
            display: none;
        }
    }
}
/* main news */

/* main content */