/* page content */
.page {
    padding-bottom: 60px;
	.news1, .news {
		.date {
			color: #ef7f1a;
		}
		a {
			text-decoration: underline;
			&:hover {
				color: #ef7f1a;
				text-decoration: none;
			}
		}
	}
	.page_nav {
		.active {
			color: #ef7f1a;
		}
	}
	@media screen and (max-width: 1170px) {
		font-size: 16px;
		#photos-list td img {
			width: 100%;
			height: auto;
		}
	}
    @media screen and (max-width: 991px) {
        padding-bottom: 25px;
    }
	@media screen and (max-width: 480px) {
		p img, p strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
	font-size: 14px;
    margin: 15px 0;
	ul {
		@include ul-default;
		li {
			padding: 5px 0px;
			color: #ef7f1a;
			@include inline-block;
			a {
                font-size: 14px;
				text-decoration: none;
			}
			&:after {
				content: '|';
				margin: 2px 0px 0px 3px;
				color: #2b2a29;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
		}
	}
}
/* breadcrumbs */

/* page catalog */
.page {
    .cat {
        background-color: #fff;
        box-shadow: none;
    }
    .cat--inside {
        background-color: #fff;
        box-shadow: none;
    }
}
.cat--unit {
    .left {
        position: relative;
        .stiker {
            position: absolute;
            top: 0px;
            right: 15px;
            line-height: 1;
            z-index: 1;
            span {
                display: block;
                font-size: 14px;
                color: #fff;
                text-align: center;
                padding: 10px 10px;
                margin-bottom: 10px;
                &.gray {
                    background-color: #2b2a29;
                }
                &.orange {
                    background-color: #ef7f1a;
                }
            }
        }
    }
    .cat--unit__for {
        @include inline-block;
        width: 458px;
        border: 1px solid #ededed;
        margin-bottom: 40px;
        .slick-slide {
            height: 310px;
            outline: none;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
    }
    .cat--unit__nav {
        @include inline-block;
        width: 85px;
        float: right;
        margin-top: 82px;
        .slick-slide {
            height: 55px;
            outline: none;
            border: 1px solid #ededed;
            margin: 12.5px 0;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
            &.slick-current {
                border: 1px solid #ef7f1a;
            }
        }
    }
    .right {
        h1 {
            font-size: 28px;
            text-align: left;
            color: #393939;
        }
        .btn {
            margin-top: 70px;
        }
    }
    .cat--unit__tabs {
        font-size: 0;
        line-height: 1;
        margin-top: 20px;
        ul {
            @include ul-default;
            li {
                width: 33.33%;
                @include inline-block;
                a {
                    display: block;
                    font-family: $sBold;
                    font-size: 20px;
                    color: #333;
                    text-align: center;
                    background-color: #ededed;
                    text-transform: uppercase;
                    text-decoration: none;
                    border: 1px solid #ef7f1a;
                    padding: 10px 10px;
                    &:hover {
                        background-color: #ef7f1a;
                        color: #fff;
                    }
                }
                &.active a {
                    background-color: #ef7f1a;
                    color: #fff;
                }
            }
        }
    }
    .cat--unit__tabs--block {
        display: none;
        padding-top: 25px;
        ul {
            @include ul-default;
            li {
                margin: 0 0 20px 0;
                span {
                    font-family: $sBold;
                }
            }
        }
        .square--list {
            li {
                margin-bottom: 10px;
            }
        }
    }
    @media screen and (max-width: 1550px) {
        .left {
            width: 585px;
        }
        .right {
            width: calc(100% - 585px);
            h1 {
                font-size: 36px;
            }
            .btn {
                margin-top: 45px;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .right {
            h1 {
                font-size: 28px;
            }
        }
        .cat--unit__tabs ul li a {
            font-size: 18px;
        }
    }
    @media screen and (max-width: 1280px) {
        .cat--unit__tabs ul li a {
            font-size: 14px;
        }
    }
    @media screen and (max-width: 1199px) {
        .left {
            width: 50%;
        }
        .cat--unit__for {
            width: calc(100% - 100px);
        }
        .right {
            width: 50%;
        }
    }
    @media screen and (max-width: 991px) {
        .left {
            width: 100%;
        }
        .cat--unit__for {
            margin-bottom: 20px;
            a {
                display: block;
                img {
                    margin: 0 auto;
                }
            }
        }
        .right {
            width: 100%;
            h1 {
                font-size: 24px;
            }
            .btn {
                margin: 25px 0;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .cat--unit__tabs {
            ul li {
                a {
                    font-size: 12px;
                    min-height: 44px;
                }
                &:nth-of-type(3) {
                    a {
                        padding-top: 15px;
                    }
                }
            }
        }
        .right {
            h1 {
                font-size: 20px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        .cat--unit__for {
            width: calc(100% - 70px);
            .slick-slide {
                height: 260px;
            }
        }
        .cat--unit__nav {
            width: 60px;
            .slick-slide {
                height: 50px;
                margin: 6px 0;
            }
        }
    }
    @media screen and (max-width: 420px) {
        .cat--unit__tabs {
            ul li {
                display: block;
                width: 100%;
                border-bottom: 1px solid #fff;
                a {
                    padding: 5px 10px;
                    min-height: 0px;
                }
                &:nth-of-type(3) {
                    a {
                        padding: 5px 10px;
                    }
                }
            }
        }
        .cat--unit__tabs--block {
            padding: 15px 0;
        }
    }
}
/* page catalog */

/* page contacts */
.contacts {
    ul {
        @include ul-default;
        li {
            margin: 0 0 15px;
            a {
                display: block;
            }
        }
    }
    .socials {
        margin: 25px 0;
        li {
            @include inline-block;
            margin: 0 15px 0 0;
            a {
                font-size: 24px;
                color: #333;
                &:hover {
                    color: #1c9eed;
                }
            }
        }
    }
    .form {
        width: 750px;
        margin: 25px auto;
        h2 {
            text-align: center;
        }
        input[type="text"],
        input[type="email"],
        textarea {
            width: 100%;
            font-size: 16px;
            border: none;
            background-color: #ededed;
            padding: 10px 20px;
            margin-bottom: 15px;
            line-height: 1;
        }
        .g-recaptcha {
            @include inline-block;
        }
        .btn {
            @include inline-block;
            float: right;
            margin: 40px 0 0 0;
        }
    }
    @media screen and (max-width: 991px) {
        .form {
            width: 100%;
        }
    }
    @media screen and (max-width: 480px) {
        .form {
            .g-recaptcha {
                float: right;
            }
            .btn {
                margin-top: 15px;
            }
        }
    }
    @media screen and (max-width: 350px) {
        .form {
            .g-recaptcha {
                transform: scale(0.86);
                transform-origin: 100% 50%;
            }
        }
    }
}
/* page contacts */

/* pagination */
.pagination {
    display: block;
    text-align: center;
    margin: 0;
    ul {
        @include ul-default;
        li {
            @include inline-block;
            margin: 0 7px 7px;
            a {
                display: block;
                font-family: $bold;
                font-size: 20px;
            }
            &.active a {
                text-decoration: underline;
                color: #ef7f1a;
            }
            &.prev, &.next {
                a {
                    text-decoration: none;
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        padding-top: 25px;
    }
}
/* pagination */

/* modal */
.modal {
	.modal-content {
        max-width: 420px;
		border-radius: 0px;
        margin: 0 auto;
		h4 {
            min-height: 0px;
			margin: 0;
		}
		.close {
			width: 25px;
			height: 25px;
			font-family: 'FontAwesome';
			font-size: 25px;
			position: absolute;
			right: 10px;
			top: 14px;
			opacity: 1;
			outline: none;
			&:after {
				content: '\f00d';
			}
			&:hover {
				opacity: 0.7;
			}
		}
        form {
            display: block;
            padding-bottom: 30px;
            margin: 15px 0 0 0; 
            input[type="text"],
            input[type="email"],
            textarea {
                width: 100%;
                font-size: 16px;
                border: none;
                background-color: #ededed;
                padding: 10px 20px;
                margin-bottom: 15px;
                line-height: 1;
            }
            .g-recaptcha {
                float: right;
                margin: 0 0 15px 0; 
            }
            .btn {
                float: right;
                margin: 0;
            }
        }
	}
    @media screen and (max-width: 380px) {
        .modal-content {
            form {
                .g-recaptcha {
                    transform: scale(.85);
                    transform-origin: 100% 50%;
                }
            }
        }
    }
}
/* modal */

/* page content */