/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on September 12, 2018 */
@font-face {
    font-family: 'exo_2.0bold';
    src: url('../fonts/exo2.0-bold-webfont.eot');
    src: url('../fonts/exo2.0-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/exo2.0-bold-webfont.woff2') format('woff2'),
         url('../fonts/exo2.0-bold-webfont.woff') format('woff'),
         url('../fonts/exo2.0-bold-webfont.svg#exo_2.0bold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'exo_2.0italic';
    src: url('../fonts/exo2.0-italic-webfont.eot');
    src: url('../fonts/exo2.0-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/exo2.0-italic-webfont.woff2') format('woff2'),
         url('../fonts/exo2.0-italic-webfont.woff') format('woff'),
         url('../fonts/exo2.0-italic-webfont.svg#exo_2.0italic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'exo_2.0light';
    src: url('../fonts/exo2.0-light-webfont.eot');
    src: url('../fonts/exo2.0-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/exo2.0-light-webfont.woff2') format('woff2'),
         url('../fonts/exo2.0-light-webfont.woff') format('woff'),
         url('../fonts/exo2.0-light-webfont.svg#exo_2.0light') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'exo_2.0medium';
    src: url('../fonts/exo2.0-medium-webfont.eot');
    src: url('../fonts/exo2.0-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/exo2.0-medium-webfont.woff2') format('woff2'),
         url('../fonts/exo2.0-medium-webfont.woff') format('woff'),
         url('../fonts/exo2.0-medium-webfont.svg#exo_2.0medium') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'exo_2.0regular';
    src: url('../fonts/exo2.0-regular-webfont.eot');
    src: url('../fonts/exo2.0-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/exo2.0-regular-webfont.woff2') format('woff2'),
         url('../fonts/exo2.0-regular-webfont.woff') format('woff'),
         url('../fonts/exo2.0-regular-webfont.svg#exo_2.0regular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'exo_2.0semi_bold';
    src: url('../fonts/exo2.0-semibold-webfont.eot');
    src: url('../fonts/exo2.0-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/exo2.0-semibold-webfont.woff2') format('woff2'),
         url('../fonts/exo2.0-semibold-webfont.woff') format('woff'),
         url('../fonts/exo2.0-semibold-webfont.svg#exo_2.0semi_bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

$regular: 'exo_2.0regular';
$bold: 'exo_2.0bold';
$sBold: 'exo_2.0semi_bold';
$medium: 'exo_2.0medium';
$light: 'exo_2.0light';
$italic: 'exo_2.0italic';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

*:after, *:before {
	display: block;
	line-height: 1;
}

body {
	display: flex;
	height: 100vh;
	position: relative;
	flex-direction: column;
	font-family: $regular;
    color: #2b2a29;
    padding-top: 156px;
	margin: 0;
    @media screen and (max-width: 991px) {
        padding-top: 97px;
    }
    @media screen and (max-width: 767px) {
        padding-top: 65px;
    }
}

h1 {
	font-family: $bold;
	font-size: 38px;
	text-align: center;
	color: #2b2a29;
	line-height: 1;
	margin: 0 0 25px;
	@media screen and (max-width: 1199px) {
		font-size: 32px;
	}
    @media screen and (max-width: 991px) {
        font-size: 28px;
    }
	@media screen and (max-width: 768px) {
		font-size: 24px;
	}
}
h2 {
	font-family: $bold;
	font-size: 36px;
	color: #2b2a29;
	margin: 0 0px 35px;	
	a {
		font-family: $bold;
		font-size: 36px!important;
		color: #2b2a29;
        text-decoration: none;
        &:hover {
            color: #ef7f1a;
            text-decoration: none;
        }
	}
	@media screen and (max-width: 1199px) {
		font-size: 30px;
		a {
			font-size: 30px!important;
		}
	}
	@media screen and (max-width: 991px) {
		font-size: 24px;
		margin-bottom: 25px;
        a {
			font-size: 24px!important;
		}
	}
    @media screen and (max-width: 767px) {
        font-size: 22px;
        a {
            font-size: 22px!important;
        }
    }
}
h3 {
	font-family: $bold;
	font-size: 28px;
	color: #2b2a29;
	@media screen and (max-width: 1199px) {
		font-size: 26px;
	}
    @media screen and (max-width: 991px) {
        font-size: 24px;
    }
    @media screen and (max-width: 767px) {
        font-size: 20px;
    }
}
h4 {
	font-family: $bold;
	font-size: 18px;
    color: #2b2a29;
}
h5 {
	font-family: $bold;
	font-size: 18px;
    color: #2b2a29;
}
h6 {
	font-family: $bold;
	font-size: 16px;
	text-transform: uppercase;
    color: #2b2a29;
}
a {
    color: #2b2a29;
	transition: 350ms;
	text-decoration: none;
	&:focus {
        color: #2b2a29;
		text-decoration: none;
	}
	&:hover {
		text-decoration: underline;
        color: #ef7f1a;
	}
}
textarea, 
button, 
select, 
*[type="text"], 
*[type="email"], 
*[type="search"],
*[type="submit"] {
	border-radius: 0px;
	-webkit-appearance: none;
}

.container {
    box-sizing: border-box;
}
.main, .page {
	position: relative;
    font-size: 16px;
	flex: 1 0 auto;
	textarea, input {
		background-color: #fff;
		font-family: $regular;
		font-size: 16px;
		padding: 5px 15px;
	}
    @media screen and (max-width: 1880px) {
        .container {
            margin: 0 0 0 380px;
        }
    }
    @media screen and (max-width: 1550px) {
        .container {
            width: 100%;
            padding-left: 380px;
            margin: 0;
        }
    }
    @media screen and (max-width: 1440px) {
        .container {
            padding-left: 315px;
        }
    }
    @media screen and (max-width: 991px) {
        font-size: 16px;
        .container {
            padding-left: 15px;
        }
    }
}
.main {
	.container {
		padding-top: 50px;
		padding-bottom: 50px;
		@media screen and (max-width: 1170px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
		@media screen and (max-width: 768px) {
			padding-top: 20px;
			padding-bottom: 20px;
		}
	}
}
.btn {
	display: table;
    height: 30px;
	width: auto!important;
	background-color: #ef7f1a!important;
	font-family: $regular!important;
	font-size: 16px;
	color: #fff!important;
	cursor: pointer;
	border-radius: 0px;
	border: 1px solid #ef7f1a;
	padding: 5px 40px!important;
	transition: 300ms;
    line-height: 1;
	&:focus, &:active {
		background-color: #ef7f1a!important;
		color: #fff;
	}
	&:hover, &.active {
		background-color: #fff!important;
		color: #ef7f1a!important;
	}	
}

.check--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		font-size: 16px;
		padding-left: 25px;
		margin-bottom: 5px;
		&:before {
			content: '\f00c';
			position: absolute;
			font-family: 'fontAwesome';
			font-size: 18px;
			color: #ef7f1a;
			left: 0;
			top: 0px;
		}
	}
}

.square--list {
    @include ul-default;
    li {
        display: block;
        position: relative;
        font-size: 16px;
        padding-left: 25px;
        margin-bottom: 5px;
        line-height: 1.2;
        &:before {
            content: '';
            width: 8px;
            height: 8px;
            background-color: #ef7f1a;
            position: absolute;
            left: 0;
            top: 4px;
        }
    }
    @media screen and (max-width: 991px) {
        li {
            font-size: 16px;
        }
    }
}

.col--location,
.col--phone,
.col--email,
.col--time {
	position: relative;
	padding-left: 25px;
	font-size: 18px;
	&:after {
		content: '';
		font-family: 'fontAwesome';
		font-size: 20px;
		color: #ef7f1a;
		position: absolute;
		top: 0px;
		left: 0;
	}
    @media screen and (max-width: 991px) {
        font-size: 16px;
    }
}
.col--location:after {
	content: '\f041';
}
.col--phone:after {
	content: '\f095';
}
.col--email {
    &:after {
        content: '\f0e0';
        font-size: 16px;
        top: 2px;
    }
    a {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}
.col--time:after {
    content: '\f017';
}

/* header */
header {
    height: 156px;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    ul {
        @include ul-default;
    }
    .up {
        min-height: 31px;
        background-color: #2b2a29;
        font-family: $light;
        font-size: 14px;
        color: #fff;
        ul {
            @include inline-block;
            margin-top: 5px;
            li {
                @include inline-block;
                font-size: 14px;
                margin: 0 70px 0 0;
                &:last-of-type {
                    margin-right: 0;
                }
                &:after {
                    color: #fff;
                    font-size: 16px;
                    top: 0px;
                }
                &.col--email {
                    &:after {
                        font-size: 14px;
                        top: 2px;
                    }
                    a {
                        text-decoration: underline;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
                p {
                    margin: 0;
                }
                a {
                    font-size: 14px;
                    color: #fff;
                }
            }
        }
    }
    .search {
        @include inline-block;
        width: 222px;
        float: right;
        margin-top: 3px;
        input[type="text"] {
            @include inline-block;
            width: calc(100% - 28px);
            background-color: transparent;
            font-family: $light;
            font-size: 14px;
            color: #fff;
            padding: 2px 0px 2px 15px;
            border: none;
            border-bottom: 1px solid #ffffff;
            line-height: 1;
            outline: none;
            &::placeholder {
                color: #fff;
            }
        }
        input[type="submit"] {
            @include inline-block;
            width: 16px;
            height: 16px;
            background-image: url(../img/search.png);
            background-position: center center;
            background-repeat: no-repeat;
            background-color: transparent;
            border: none;
            outline: none;
            float: right;
            margin: 4px 0 0 0;
        }
    }

    > .container {
        position: relative;
    }

	/* header phones */
	.phones {
        position: absolute;
        top: 43px;
        right: 15px;
        padding-left: 30px;
        &.more {
            &:before {
                content: '\f107';
                font-family: 'fontAwesome';
                font-size: 18px;
                color: #333;
                position: absolute;
                top: 3px;
                right: -15px;
            }
        }
        &:after {
            content: '\f095';
            font-family: 'fontAwesome';
            font-size: 20px;
            color: #ef7f1a;
            position: absolute;
            top: 1px;
            left: 0;
        }
        a {
            font-family: $sBold;
            font-size: 18px;
            color: #333333;
            text-decoration: none;
        }
        ul {
            max-height: 0px;
            background-color: rgba(239, 127, 26, 0.7);
            overflow: hidden;
            position: absolute;
            top: 100%;
            right: -10px;
            transition: 400ms;
            li {
                a {
                    display: block;
                    color: #fff;
                    padding: 5px 10px 0px;
                }
                &:last-child a {
                    padding-bottom: 5px;
                }
            }
        }
        &:hover {
            ul {
                max-height: 200px;
            }
        }
    }
	/* header phones */

	/* header logo */
	.logo {
        display: block;
        width: 165px;
        margin: 8px 0 8px 0;
    }
	/* header logo */

    /* header slogan */
    .slogan {
        font-family: $sBold;
        font-size: 16px;
        text-transform: uppercase;
        position: absolute;
        top: 20px;
        left: 220px;
    }
    /* header slogan */

    /* header callback */
    .callback {
        font-family: $regular;
        font-size: 16px;
        color: #ef7f1a;
        text-decoration: underline;
        position: absolute;
        top: 20px;
        right: 15px;
        &:hover {
            text-decoration: none;
        }
    }
    /* header callback */

    .down {
        min-height: 40px;
        background-color: #ededed;
    }
	/* header nav */
	.nav {
        text-align: justify;
        text-align-last: justify;
        margin: 6px 0 0 0;
        > ul > li {
            @include inline-block;
            > a {
                display: block;
                font-family: $regular;
                font-size: 18px;
                color: #333333;
                text-decoration: none;
                border-bottom: 2px solid transparent;
                &:hover {
                    text-decoration: none;
                    border-bottom: 2px solid #ef7f1a;
                }
            }
            &.active > a {
                border-bottom: 2px solid #ef7f1a;
            }
        }
    }
	/* header nav */

	/* header langs */
	
	/* header langs */
	
    @media screen and (max-width: 1199px) {
        .up {
            ul {
                li {
                    margin-right: 15px;
                }
            }
        }
        .search {
            width: 180px;
        }
    }

    @media screen and (min-width: 992px) {
        .down {
            &.fixed {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                z-index: 1;
            }
        }
    }
	@media screen and (max-width: 991px) {
        position: fixed;
        height: auto;
        border-bottom: 2px solid #ef7f1b;
        .up {
            min-height: 0;
            ul {
                margin-top: 3px;
                li {
                    display: block;
                    font-size: 12px;
                    margin: 0px 0;
                    &.col--location,
                    &.col--email {
                        display: none;
                    }
                }
            }
        }
        .search {
            margin-top: 2px;
            input[type="text"] {
                padding: 0px 0 0px 10px
            }
            input[type="submit"] {
                width: 14px;
                height: 14px;
                background-size: contain;
                margin-top: 2px;
            }
        }
        .down {
            min-height: 0;
        }
        .logo {
            width: 115px;
            margin: 5px auto 20px;
            img {
                width: 100%;
            }
        }
        .slogan {
            font-size: 10px;
            text-align: center;
            position: absolute;
            top: 58px;
            left: 0;
            right: 0;
            br {
                display: none;
            }
        }
        .callback {
            top: 10px;
        }
        .phones {
            top: 32px;
        }
        .nav {
            display: none;
            max-height: calc(100% - 97px);
            background-color: #fff;
            text-align: left;
            text-align-last: left;
            border-bottom: 2px solid #ef7f1b;
            border-top: 2px solid #ef7f1b;
            position: fixed;
            top: 97px;
            left: 0;
            right: 0;
            margin: 0;
            overflow: auto;
            > ul {
                width: 750px;
                padding: 0 15px;
                margin: 0 auto;
                > li {
                    display: block;
                    margin: 5px 0;
                    a {
                        display: table;
                        font-size: 16px;
                    }
                }
            }
        }
		.nav--btn {
			width: 35px;
			height: 30px;
			position: absolute;
			left: 12px;
			top: 18px;
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
			span {
				position: absolute;
				left: 0;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: #ef7f1b;
				transition: 350ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}
	}
    @media screen and (max-width: 767px) {
        .logo {
            width: 80px;
            margin: 5px 0px 5px 0px;
        }
        .phones {
            display: none;
            background-color: rgba(0,0,0, 0.8);
            text-align: center;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            padding: 10px;
            z-index: 1;
            &:after, &:before {
                display: none;
            }
            a {
                font-size: 16px;
                color: #fff;
            }
            ul {
                max-height: 100%;   
                background-color: transparent;
                position: relative;
                top: 0;
                left: 0;
                li a {
                    color: #fff;
                    padding: 2px 0;
                }
            }
        }
        .slogan {
            width: 175px;
            font-size: 9px;
            text-align: left;
            left: 110px;
            top: 10px;
            right: auto;
        }
        .callback {
            font-size: 12px;
            top: -18px;
        }
        .nav {
            max-height: calc(100% - 64px);
            top: 64px;
        }
        .nav--btn {
            left: auto;
            right: 10px;
            top: 7px;
        }
        .phone--btn {
            display: block;
            font-family: 'fontAwesome';
            font-size: 22px;
            color: #ef7f1b;
            position: absolute;
            top: 13px;
            right: 75px;
            &:after {
                content: '\f095';
            }
            &:hover {
                text-decoration: none;
            }
        }
        .search {
            display: none;
            width: 100%;
            background-color: rgba(0,0,0,0.8);
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            padding: 10px;
            z-index: 1;
        }
        .search--btn {
            display: block;
            font-family: 'fontAwesome';
            font-size: 19px;
            color: #ef7f1b;
            position: absolute;
            top: 12px;
            right: 50px;
            &:after {
                content: '\f002';
            }
            &:hover {
                text-decoration: none;
            }
        }
    }
    @media screen and (max-width: 420px) {
        .up {
            .container {
                padding: 0 10px;
            }
            ul {
                margin-top: 4px;
                li {
                    font-size: 9px;
                    &.col--time {
                        padding-left: 16px;
                        padding-top: 1px;
                        &:after {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
        .callback {
            font-size: 9px;
            top: -16px;
            right: 10px;
        }
        .logo {
            width: 70px;
            margin: 8px 0px 8px -10px;
        }
        .slogan {
            width: 140px;
            left: 82px;
            top: 7px;
        }
    }
}
/* header */