/* footer */
footer {
    font-size: 16px;
    color: #fff;
    background-color: #2b2a29;
    padding-top: 48px;
    ul {
        @include ul-default;
        li {
            font-size: 16px!important;
            margin-bottom: 15px;
            &.col--location {
                &:after {
                    top: 0;
                }
            }
            &.col--phone {
                &:after {
                    top: 0;
                }
            }
            &.active a {
                color: #ef7f1a;
            }
        }
    }
    h5 {
        color: #fff;
        text-transform: uppercase;
        margin: 0 0 30px 0;
    }
    a {
        display: block;
        font-size: 16px;
        color: #fff;
        &:hover {
            text-decoration: none;
        }
    }
    .footer--contacts {
        padding-left: 100px;
    }
    .socials {
        @include ul-default;
        margin: 20px 0 0 0;
        li {
            @include inline-block;
            margin: 0 15px 0 0;
            a {
                font-size: 24px;
                &:hover {
                    color: #1da1f2;
                }
            }
        }
    }
    .container-fluid {
        background-color: #ef7f1a;
        padding: 14px 0;
        margin-top: 45px;
        p {
            @include inline-block;
            margin: 0;
        }
        .artmedia {
            @include inline-block;
            color: #fff;
            text-decoration: none;
            position: relative;
            float: right;
            padding-right: 130px;
            img {
                position: absolute;
                top: -4px;
                right: 0;
            }
        }
    }
    @media screen and (max-width: 1880px) {
        .container {
            margin: 0 0 0 380px;
        }
    }
    @media screen and (max-width: 1550px) {
        .container {
            width: 100%;
            padding-left: 380px;
            margin: 0;
        }
    }
    @media screen and (max-width: 1440px) {
        .container {
            padding-left: 315px;
        }
    }
    @media screen and (max-width: 1199px) {
        .footer--contacts {
            padding-left: 15px;
        }
    }
    @media screen and (max-width: 991px) {
        padding-top: 25px;
        .container {
            padding-left: 15px;
        }
        h5 {
            margin-bottom: 10px;
        }
        ul {
            margin-bottom: 25px;
            li {
                margin-bottom: 7px;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .container-fluid {
            margin-top: 20px;
            p {
                display: block;
                text-align: center;
            }
            .artmedia {
                display: table;
                float: none;
                margin: 10px auto 0;
            }
        }
    }
}
/* footer */